import * as React from "react";
import { LocationHash } from "../classes/hash";
import { IVVejledning } from "../classes/IVVejledning";
import { IVListItem, ItemType } from "./IVListItem";
import '@fluentui/react/dist/css/fabric.css';
import { Container } from "react-bootstrap";
import { IVListTop } from "./IVListTop";

export interface IVListProps {
    items: Array<IVVejledning>;
}
export interface IVListState {
    items: Array<IVVejledning>;
    itemsLoaded: boolean;
    atcCode: string;
    filter: string;
}
export class IVList extends React.Component<IVListProps, IVListState> {
    //#region properties
    
    //#endregion

    //#region lifecycle
    constructor(props: IVListProps) {
        super(props);
        this.state = {
            items: this.props.items,
            itemsLoaded: false,
            atcCode: '',
            filter: ''
        }

        this.sortCol = this.sortCol.bind(this);
        this.onFilter = this.onFilter.bind(this);
        this.onAtcFilter = this.onAtcFilter.bind(this);
    }

    componentDidMount(): void {
        let hash: LocationHash = new LocationHash();
    }

    public render(): React.ReactElement<IVListProps> {
        const header = new IVVejledning();
        let hash: LocationHash = new LocationHash();
        window.document.title = "IV-Vejledninger";
        if (this.state.itemsLoaded && (this.state.atcCode !== (hash.hashes.atcfilter === undefined? "" : hash.hashes.atcfilter) || this.state.filter !== (hash.hashes.filter === undefined? "" : hash.hashes.filter))) {
            let search = (hash.hashes.filter !== undefined ? hash.hashes.filter : ""); 
            this.onFilter(search, hash.hashes.atcfilter);
            this.setState({ filter: search });
            // this.setState({ atcCode: hash.hashes.atcfilter });
        }
        if (this.state.items.length<this.props.items.length && !this.state.itemsLoaded) {
            this.setState({items:this.props.items, itemsLoaded: true});
        }

        return <Container fluid>
            <IVListTop items={this.props.items} filteredItems={this.state.items} onSearch={this.onFilter}/>
            <IVListItem ListItem={header} ItemType={ItemType.Header} OnColClick={this.sortCol} />
            {this.state.items.map(item => {
                return <IVListItem ListItem={item} ItemType={ItemType.Even} OnColClick={this.selectItem} OnAtcFilter={this.onAtcFilter} AtcFilter={this.state.atcCode}/>
            })}
        </Container>
    }
    //#endregion

    //#region methods
    private sortCol(sortCol: string, ascending: boolean): void {
        let listItems = this.state.items;
        listItems.sort((a: any, b: any) => {
            let returnValue = 0;
            if (a[sortCol] > b[sortCol]) {
                returnValue = -1;
            } else {
                returnValue = 1;
            }

            if (ascending) {
                returnValue = returnValue * -1;
            }
            return returnValue;
        });

        this.setState({items: listItems});
    }

    /**
     * selectItem
    */
    public selectItem(id:any) {
        let hash: LocationHash = new LocationHash();
        hash.hashes.itemid = id;
        hash.Set();
    }

    private onFilter(value: string, atcCode?: string) {
        let listItems = this.props.items;
        let hash: LocationHash = new LocationHash();
        if (value === "" || value === "undefined") {
            delete hash.hashes.filter;
        } else {
            hash.hashes.filter = value;
        }
        hash.Set();

        listItems = listItems.filter((item: IVVejledning) => {
            let returnValue = false;
            let searchFields: Array<string> = [
                "Title",
                "IVCardFieldATCCode",
                "IVCardFieldGenericName",
                "IVCardFieldSubstanceInfo1",
                "IVCardFieldSubstanceInfo2",
                "IVCardFieldSubstanceInfo3",
                "IVCardFieldMoreInfo",
                "IVCardFieldAdministration",
                "IVCardFieldRateOfAdministration",
                "IVCardFieldMaxConcentration",
                "IVCardFieldDurability",
                "IVCardFieldAdditionalInfo"
            ]

            searchFields.forEach(searchField => {
                try {
                    returnValue = returnValue || ((item as any)[searchField].toString().toLowerCase().indexOf(value.toLowerCase()) >=0)
                } catch (error) {
                    
                }
            });

            if (atcCode === undefined) {
                atcCode = this.state.atcCode;
            }
            if (returnValue) {
                if (atcCode !== '') {
                    returnValue = item.IVCardFieldATCCode === atcCode;                    
                }
            }
            return returnValue;
        });

        this.setState({items: listItems, atcCode: atcCode===undefined ? this.state.atcCode: atcCode});
        
    }

    private onAtcFilter(atcCode: string) {
        let hash: LocationHash = new LocationHash();

        if (atcCode === "") {
            delete hash.hashes.atcfilter
        } else {
            hash.hashes.atcfilter = atcCode;
        }
        hash.Set();

        this.onFilter(hash.hashes.filter, atcCode);
    }
    //#endregion
}