import './App.css';
import { IVApp } from './controls/IVApp';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fluentui/react/dist/css/fabric.css';
import { initializeIcons } from '@fluentui/react';

function App() {
  initializeIcons();
  return (
    <div className="App">
      <IVApp />
    </div>
  );
}

export default App;
