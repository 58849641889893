export class LocationHash {
    //#region properties
    
    private _hashes : any = {};
    public get hashes() : any {
        return this._hashes;
    }
    public set hashes(v : any) {
        this._hashes = v;
    }
    

    // private _hashString : string = "";
    public get hashString() : string {
        let returnValue = ""
        for (const prop in this.hashes) {
            if (prop !== "") {
                returnValue += `${prop}=${this.hashes[prop]}&`                
            }
        }
        return returnValue
        // return this._hashString;
    }
    public set hashString(v : string) {
        this.hashes = {};
        console.log("FFFFFFFFFFFF")
        if (v.startsWith("#")) {
            let hashItems = v.split('&');
            hashItems.forEach(hashItem => {
                if (hashItem.startsWith('#')) {
                    hashItem = hashItem.substring(1);
                }
                this.hashes[hashItem.split('=')[0]] = hashItem.split('=')[1];
            });                
        }
        // this._hashString = v;
    }
    //#endregion

    //#region lifecycle
    public constructor() {
        this.hashString = window.location.hash;
    }
    //#endregion   

    //#region methods
    /**
     * Clear
     */
    public Clear() {
        window.location.hash = "";
    }

    public Set() {
        window.location.hash = this.hashString;
    }

    //#endregion
}