import { IIVVejledning } from "../interfaces/IIVVejledning";

export class IVVejledning implements IIVVejledning {
    //#region properties
    
    private _ID : number = 0;
    public get ID() : number {
        return this._ID;
    }
    public set ID(v : number) {
        this._ID = v;
    }

    private _Title : string = "";
    public get Title() : string {
        return this._Title;
    }
    public set Title(v : string) {
        this._Title = v;
    }
    
    private _IVCardFieldATCCode : string = "";
    public get IVCardFieldATCCode() : string {
        return this._IVCardFieldATCCode;
    }
    public set IVCardFieldATCCode(v : string) {
        this._IVCardFieldATCCode = v;
    }
    
    private _IVCardFieldGenericName : string = "";
    public get IVCardFieldGenericName() : string {
        return this._IVCardFieldGenericName;
    }
    public set IVCardFieldGenericName(v : string) {
        this._IVCardFieldGenericName = v;
    }
    
    private _IVCardFieldSubstanceInfo1 : string = "";
    public get IVCardFieldSubstanceInfo1() : string {
        return this._IVCardFieldSubstanceInfo1;
    }
    public set IVCardFieldSubstanceInfo1(v : string) {
        this._IVCardFieldSubstanceInfo1 = v;
    }
    
    private _IVCardFieldSubstanceInfo2 : string = "";
    public get IVCardFieldSubstanceInfo2() : string {
        return this._IVCardFieldSubstanceInfo2;
    }
    public set IVCardFieldSubstanceInfo2(v : string) {
        this._IVCardFieldSubstanceInfo2 = v;
    }
    
    private _IVCardFieldSubstanceInfo3 : string = "";
    public get IVCardFieldSubstanceInfo3() : string {
        return this._IVCardFieldSubstanceInfo3;
    }
    public set IVCardFieldSubstanceInfo3(v : string) {
        this._IVCardFieldSubstanceInfo3 = v;
    }

    
    private _IVCardFieldApprovedDate : Date = new Date(Date.now());
    public get IVCardFieldApprovedDate() : Date {
        return this._IVCardFieldApprovedDate;
    }
    public set IVCardFieldApprovedDate(v : Date) {
        this._IVCardFieldApprovedDate = v;
    }
        
    private _IVCardFieldItemNumber : string = "";
    public get IVCardFieldItemNumber() : string {
        return this._IVCardFieldItemNumber;
    }
    public set IVCardFieldItemNumber(v : string) {
        this._IVCardFieldItemNumber = v;
    }
    
    private _IVCardFieldAdministration : string = "";
    public get IVCardFieldAdministration() : string {
        return this._IVCardFieldAdministration;
    }
    public set IVCardFieldAdministration(v : string) {
        this._IVCardFieldAdministration = v;
    }
    
    private _IVCardFieldRateOfAdministration : string = "";
    public get IVCardFieldRateOfAdministration() : string {
        return this._IVCardFieldRateOfAdministration;
    }
    public set IVCardFieldRateOfAdministration(v : string) {
        this._IVCardFieldRateOfAdministration = v;
    }
    
    private _IVCardFieldMaxConcentration : string = "";
    public get IVCardFieldMaxConcentration() : string {
        return this._IVCardFieldMaxConcentration;
    }
    public set IVCardFieldMaxConcentration(v : string) {
        this._IVCardFieldMaxConcentration = v;
    }
    
    private _IVCardFieldDurability : string = "";
    public get IVCardFieldDurability() : string {
        return this._IVCardFieldDurability;
    }
    public set IVCardFieldDurability(v : string) {
        this._IVCardFieldDurability = v;
    }
    
    private _IVCardFieldPreservative : string = "";
    public get IVCardFieldPreservative() : string {
        return this._IVCardFieldPreservative;
    }
    public set IVCardFieldPreservative(v : string) {
        this._IVCardFieldPreservative = v;
    }
    
    private _IVCardFieldAdditionalInfo : string = "";
    public get IVCardFieldAdditionalInfo() : string {
        return this._IVCardFieldAdditionalInfo;
    }
    public set IVCardFieldAdditionalInfo(v : string) {
        this._IVCardFieldAdditionalInfo = v;
    }
    
    private _IVCardFieldMoreInfo : string = "";
    public get IVCardFieldMoreInfo() : string {
        return this._IVCardFieldMoreInfo;
    }
    public set IVCardFieldMoreInfo(v : string) {
        this._IVCardFieldMoreInfo = v;
    }
    
    private _IVCardFieldCanMixWith : string = "";
    public get IVCardFieldCanMixWith() : string {
        return this._IVCardFieldCanMixWith;
    }
    public set IVCardFieldCanMixWith(v : string) {
        this._IVCardFieldCanMixWith = v;
    }
    
    
    //#endregion
}