import * as React from "react";
import { LocationHash } from "../classes/hash";
import { Col, Container, Row } from "react-bootstrap";
import { Icon } from "@fluentui/react";
import { IVInfoBox, InfoBoksTypes } from "./IVInfoBox";
import { IVVejledning } from "../classes/IVVejledning";
import { Data } from "../services/data";

export interface IIVItemProps {
    ListItem: IVVejledning;
}

export class IVItem extends React.Component<IIVItemProps,{}> {
    //#region properties
    //#endregion

    //#region lifecycle
    constructor(props: IIVItemProps) {
        super(props);
    }

    public render(): React.ReactElement<IIVItemProps> {
        const bold: React.CSSProperties = {fontWeight: "bold"};

        if (this.props.ListItem === undefined) {
            return <div></div>
        }

        window.document.title = `IV-Vejledninger - ${this.props.ListItem.IVCardFieldItemNumber} ${this.props.ListItem.IVCardFieldSubstanceInfo1}`;
        return <Container fluid>
                    <Row>
                        <Col md={11} xl={11} ><img src="modalLogo.png" alt="IV-Vejledninger" style={{float:"right" }} /></Col>
                        <Col md={1} xl={1}><Icon iconName="ChromeClose" onClick={this.closeItem} style={{cursor:"pointer", float:"right"}}/></Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={8} xl={9} className="ivBox" >
                            <IVInfoBox ListItem={this.props.ListItem} InfoBoksType={InfoBoksTypes.MultiInfo} SetupInfo={ [ Data.GetFieldSetting("IVCardFieldSubstanceInfo1"), Data.GetFieldSetting("IVCardFieldSubstanceInfo2"), Data.GetFieldSetting("IVCardFieldSubstanceInfo3"), Data.GetFieldSetting("IVCardFieldMoreInfo") ]}/>
                        </Col>
                        <Col sm={12} md={4} xl={3} className="ivBox" >
                            <IVInfoBox ListItem={this.props.ListItem} InfoBoksType={InfoBoksTypes.SingleInfo} SetupInfo={ Data.GetFieldSetting("IVCardFieldGenericName") } />
                            <IVInfoBox ListItem={this.props.ListItem} InfoBoksType={InfoBoksTypes.SingleInfo} SetupInfo={ Data.GetFieldSetting("IVCardFieldATCCode") } />
                            <IVInfoBox ListItem={this.props.ListItem} InfoBoksType={InfoBoksTypes.SingleInfo} SetupInfo={ Data.GetFieldSetting("IVCardFieldItemNumber") } />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={12} xl={12} className="ivBox" >
                            <IVInfoBox ListItem={this.props.ListItem} InfoBoksType={InfoBoksTypes.SingleInfo} SetupInfo={ Data.GetFieldSetting("IVCardFieldAdministration") }/>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={8} xl={9} className="ivBox" >
                            <IVInfoBox ListItem={this.props.ListItem} InfoBoksType={InfoBoksTypes.SingleInfo} SetupInfo={ Data.GetFieldSetting("IVCardFieldRateOfAdministration") } />
                        </Col>
                        <Col sm={12} md={4} xl={3} className="ivBox" >
                            <IVInfoBox ListItem={this.props.ListItem} InfoBoksType={InfoBoksTypes.SingleInfo} SetupInfo={ Data.GetFieldSetting("IVCardFieldMaxConcentration") } />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={12} xl={12} className="ivBox" >
                            <IVInfoBox ListItem={this.props.ListItem} InfoBoksType={InfoBoksTypes.Matrix} SetupInfo={ Data.GetFieldSetting("Mixing") } />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={8} xl={9} className="ivBox" >
                            <IVInfoBox ListItem={this.props.ListItem} InfoBoksType={InfoBoksTypes.SingleInfo} SetupInfo={ Data.GetFieldSetting("IVCardFieldDurability") }/>
                        </Col>
                        <Col sm={12} md={4} xl={3} className="ivBox" >
                            <IVInfoBox ListItem={this.props.ListItem} InfoBoksType={InfoBoksTypes.SingleInfo} SetupInfo={ Data.GetFieldSetting("IVCardFieldPreservative") } />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={12} xl={12} className="ivBox" >
                            <IVInfoBox ListItem={this.props.ListItem} InfoBoksType={InfoBoksTypes.SingleInfo} SetupInfo={ Data.GetFieldSetting("IVCardFieldAdditionalInfo") } />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={12} xl={12} className="ivBox" >
                            <IVInfoBox ListItem={this.props.ListItem} InfoBoksType={InfoBoksTypes.SingleInfo} SetupInfo={ Data.GetFieldSetting("Sources") } />
                        </Col>
                    </Row>
                </Container>;
    }
    //#endregion

    //#region methods
    /**
     * selectItem
    */
    public closeItem() {
        let hash: LocationHash = new LocationHash();
        delete hash.hashes["itemid"];
        hash.hashes["showlist"] ="1";
        hash.Set();
    }
    //#endregion
}