import * as React from "react";
import { IVVejledning } from "../classes/IVVejledning";
import '@fluentui/react/dist/css/fabric.css';
import { Col, Row } from "react-bootstrap";
import { SearchBox } from "@fluentui/react";
import { LocationHash } from "../classes/hash";

export interface IVListTopProps {
    filteredItems: Array<IVVejledning>;
    items: Array<IVVejledning>;
    onSearch: (value: string) => void;
}
export interface IVListTopState {
}
export class IVListTop extends React.Component<IVListTopProps, IVListTopState> {
    //#region properties
    //#endregion

    //#region lifecycle
    constructor(props: IVListTopProps) {
        super(props);
        this.state = {
            items: this.props.items
        }
    }

    public render(): React.ReactElement<IVListTopProps> {
        const header = new IVVejledning();
        let hash: LocationHash = new LocationHash();
        let search = hash.hashes.filter;
        
        return <Row className="listTop">
            <Col>Viser {this.props.filteredItems.length} af {this.props.items.length}</Col>
            <Col><SearchBox placeholder="Søg" defaultValue={search} onSearch={this.props.onSearch} onClear={() => this.props.onSearch("")}/></Col>
            </Row>
    }
    //#endregion

    //#region methods
    //#endregion
}