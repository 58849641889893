import * as React from "react";
import { LocationHash } from "../classes/hash";
import { IVVejledning } from "../classes/IVVejledning";
import { Col, Row } from "react-bootstrap";
import { FontIcon, Icon, PrimaryButton } from "@fluentui/react";
import '@fluentui/react/dist/css/fabric.css';
import { format } from "date-fns";

export enum ItemType {
    Header = "Header",
    Odd = "Odd",
    Even = "Even"
}
export interface IIVListItemProps {
    ListItem: IVVejledning;
    ItemType: ItemType;
    AtcFilter?: string;
    OnColClick?: (sortCol: string, ascending: boolean)=>void;
    OnAtcFilter?: (atcCode: string) => void;
}
export interface IIVListItemState {
    SortedColumn: string;
    SortedOrderAscending: boolean;
}
export class IVListItem extends React.Component<IIVListItemProps, IIVListItemState> {
    //#region properties
    
    private _sortCol : string = "IVCardFieldSubstanceInfo1";
    public get sortCol() : string {
        return this._sortCol;
    }
    public set sortCol(v : string) {
        this._sortCol = v;
    }
    
    
    private _ascending : boolean = true;
    public get ascending() : boolean {
        return this._ascending;
    }
    public set ascending(v : boolean) {
        this._ascending = v;
    }
    
    //#endregion

    //#region lifecycle
    constructor(props: IIVListItemProps) {
        super(props);
        this.state = {
            SortedColumn: this.sortCol,
            SortedOrderAscending: true
        }
        this.onColClick = this.onColClick.bind(this);
    }

    componentDidMount(): void {
        // this.isDesktopResolution = this.useMatchMedia('(min-width:992px)', true)        
    }
    public render(): React.ReactElement<IIVListItemProps> {
        const watcher = window.matchMedia('(min-width:992px)');
        let icon = <FontIcon aria-label="CaretSolidDown" iconName="CaretSolidDown" />;
        if (this.ascending) {
            icon = <FontIcon aria-label="CaretSolidUp" iconName="CaretSolidUp" />;
        }
        let filterText = this.props.AtcFilter=="" ? "Relevante" : "Alle"
        let styles: any = {};
        styles[this.sortCol] = {fontWeight:"bold"};
        switch (this.props.ItemType) {
            case ItemType.Header:
                if (!watcher.matches) {
                    return <span />
                }
                return <Row className="">
                        <Col md={6} xl={6} onClick={this.onColClick} data-sorttype="IVCardFieldSubstanceInfo1" style={styles.IVCardFieldSubstanceInfo1}>Varenavn {this.sortCol=="IVCardFieldSubstanceInfo1" ? icon :<span/>}</Col>
                        <Col md={6} xl={2} onClick={this.onColClick} data-sorttype="IVCardFieldGenericName" style={styles.IVCardFieldGenericName}>Generisk navn {this.sortCol=="IVCardFieldGenericName" ? icon :<span/>}</Col>
                        <Col md={6} xl={2} onClick={this.onColClick} data-sorttype="IVCardFieldApprovedDate" style={styles.IVCardFieldApprovedDate}>Dato {this.sortCol=="IVCardFieldApprovedDate" ? icon :<span/>}</Col>
                        <Col md={6} xl={2} onClick={this.onColClick} data-sorttype="IVCardFieldATCCode" style={styles.IVCardFieldATCCode}>ATC-kode {this.sortCol=="IVCardFieldATCCode" ? icon :<span/>}</Col>
                    </Row>;
                            
            default:
                return <Row className="listItem" data-itemid={this.props.ListItem.ID}>
                        <Col md={6} xl={6} onClick={this.onColClick}  >
                            <b >{this.props.ListItem.IVCardFieldSubstanceInfo1}</b><br/>
                            {this.props.ListItem.IVCardFieldSubstanceInfo2}, {this.props.ListItem.IVCardFieldSubstanceInfo3}<br/>
                            {this.props.ListItem.IVCardFieldMoreInfo}<br/>
                        </Col>
                        <Col md={6} xl={2} onClick={this.onColClick} >{this.props.ListItem.IVCardFieldGenericName}</Col>
                        <Col md={6} xl={2} onClick={this.onColClick} >{this.formatDate(this.props.ListItem.IVCardFieldApprovedDate)}</Col>
                        <Col md={6} xl={2} data-itemid={this.props.ListItem.ID} >{this.props.ListItem.IVCardFieldATCCode}<br/>
                        <PrimaryButton className="button" onClick={() => {
                            let atcCode: string = this.props.AtcFilter=="" ? this.props.ListItem.IVCardFieldATCCode : "";
                             this.props.OnAtcFilter?.(atcCode); }}>{filterText}</PrimaryButton>
                        </Col>
                    </Row>;
        }

    }
    formatDate(IVCardFieldApprovedDate: Date | string): string {
        let returnValue: string;
        if (IVCardFieldApprovedDate===undefined) {
            returnValue = "";
        } else {
            returnValue = format(IVCardFieldApprovedDate, "dd.MM.yyyy");
        }
        return returnValue;
    }
    //#endregion

    //#region methods
    private onColClick(event: any) {
        console.log(event)
        let target = event.target.localName !== 'i' ? event.target : event.target.parentElement;
        
        if (target.dataset.sorttype === undefined) {
            while (target.dataset.itemid === undefined) {
                target = target.parentElement;
            }
            this.props.OnColClick?.(target.dataset.itemid, this.ascending);
        } else {
            this.sortCol = target.dataset.sorttype;
            this.ascending = !this.ascending;
            this.props.OnColClick?.(target.dataset.sorttype, this.ascending);    
        }
    }

    /**
     * selectItem
    */
    public selectItem(item:any) {
        let hash: LocationHash = new LocationHash();
        hash.Clear();
    }
    //#endregion
}