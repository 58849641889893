import * as React from "react";
import { IVVejledning } from "../classes/IVVejledning";
import { Icon, TooltipHost, getId } from "@fluentui/react";
import '@fluentui/react/dist/css/fabric.css';
import { Data } from "../services/data";
import { Col, Container, Row } from "react-bootstrap";

export enum InfoBoksTypes {
    SingleInfo = "SingleInfo",
    MultiInfo = "MultiInfo",
    Matrix = "Matrix"
}

export interface IVRow {
    Cols: Array<IVCol>
}
export interface IVCol {
    Title?:string;
    TitleField?:string;
}

export interface IIVSetupInfo {
    Title: string;
    Name: string;
    Tooltip?: string;
    IconName?: string;
    Style?: React.CSSProperties;
    GeneralField?: boolean;
    HideTitle?: boolean;
    Rows?: Array<IVRow>;
}
export interface IIVInfoBoxProps {
    ListItem: IVVejledning;
    InfoBoksType: InfoBoksTypes;
    SetupInfo: Array<IIVSetupInfo> | IIVSetupInfo;
}

export interface IIVInfoBoxTopProps {
    SetupInfo: Array<IIVSetupInfo> | IIVSetupInfo;
    ListItem: IVVejledning;
    NotLast?: boolean;
}

export class IVInfoBox extends React.Component<IIVInfoBoxProps,{}> {
    //#region properties
    
    private _sortCol : string = "IVCardFieldSubstanceInfo1";
    public get sortCol() : string {
        return this._sortCol;
    }
    public set sortCol(v : string) {
        this._sortCol = v;
    }
    
    
    private _ascending : boolean = true;
    public get ascending() : boolean {
        return this._ascending;
    }
    public set ascending(v : boolean) {
        this._ascending = v;
    }
    
    //#endregion

    //#region lifecycle
    constructor(props: IIVInfoBoxProps) {
        super(props);
    }

    componentDidMount(): void {
        // this.isDesktopResolution = this.useMatchMedia('(min-width:992px)', true)        
    }
    public render(): React.ReactElement<IIVInfoBoxProps> {
        switch (this.props.InfoBoksType) {
            case InfoBoksTypes.MultiInfo:
                (this.props.SetupInfo as Array<IIVSetupInfo>).forEach(setupInfo => {
                    
                });
                return <div>
                            <div className="ivTop">
                            { (this.props.SetupInfo as Array<IIVSetupInfo>).filter(info => !info.HideTitle).map((setupInfo, index) => {
                                return <IVInfoBoxTop SetupInfo={setupInfo}  ListItem={this.props.ListItem} NotLast={index<((this.props.SetupInfo as Array<IIVSetupInfo>).filter(info => !info.HideTitle).length-1)}/>
                            })}
                            </div>
                            { (this.props.SetupInfo as Array<IIVSetupInfo>).map((setupInfo, index) => {
                                return <IVInfoBoxInfo SetupInfo={setupInfo}  ListItem={this.props.ListItem} NotLast={index<((this.props.SetupInfo as Array<IIVSetupInfo>).length-1)}/>
                            })}
                            
                        </div>
            case InfoBoksTypes.Matrix:
                return  <div>
                            <div className="ivTop"><IVInfoBoxTop SetupInfo={this.props.SetupInfo}  ListItem={this.props.ListItem}/></div>
                            <IVInfoBoxInfoMatrix SetupInfo={this.props.SetupInfo} ListItem={this.props.ListItem} />
                        </div>
            default:
                return <div>
                    <div className="ivTop"><IVInfoBoxTop SetupInfo={this.props.SetupInfo}  ListItem={this.props.ListItem}/></div>
                    <IVInfoBoxInfo SetupInfo={this.props.SetupInfo} ListItem={this.props.ListItem}/>
                </div>
        }
        // const watcher = window.matchMedia('(min-width:992px)');
        // let icon = <FontIcon aria-label="Compass" iconName="CompassNW" />;
        // let filterText = this.props.AtcFilter=="" ? "Relevante" : "Alle"
        // let styles: any = {};
        // styles[this.sortCol] = {fontWeight:"bold"};
        // switch (this.props.ItemType) {
        //     case ItemType.Header:
        //         if (!watcher.matches) {
        //             return <span />
        //         }
        //         return <Row className="">
        //                 <Col md={6} xl={6} onClick={this.onColClick} data-sorttype="IVCardFieldSubstanceInfo1" style={styles.IVCardFieldSubstanceInfo1}>Varenavn <FontIcon aria-label="Compass" iconName="CompassNW" /></Col>
        //                 <Col md={6} xl={2} onClick={this.onColClick} data-sorttype="IVCardFieldGenericName" style={styles.IVCardFieldGenericName}>Generisk navn</Col>
        //                 <Col md={6} xl={2} onClick={this.onColClick} data-sorttype="IVCardFieldApprovedDate" style={styles.IVCardFieldApprovedDate}>Dato</Col>
        //                 <Col md={6} xl={2} onClick={this.onColClick} data-sorttype="IVCardFieldATCCode" style={styles.IVCardFieldATCCode}>ATC-kode</Col>
        //             </Row>;
                            
        //     default:
        //         return <Row className="listItem">
        //                 <Col md={6} xl={6} onClick={this.onColClick} data-itemid={this.props.ListItem.ID}>
        //                     <b>{this.props.ListItem.IVCardFieldSubstanceInfo1}</b><br/>
        //                     {this.props.ListItem.IVCardFieldSubstanceInfo2}<br/>
        //                     {this.props.ListItem.IVCardFieldSubstanceInfo3}<br/>
        //                 </Col>
        //                 <Col md={6} xl={2} onClick={this.onColClick} data-itemid={this.props.ListItem.ID}>{this.props.ListItem.IVCardFieldGenericName}</Col>
        //                 <Col md={6} xl={2} onClick={this.onColClick} data-itemid={this.props.ListItem.ID}>{this.formatDate(this.props.ListItem.IVCardFieldApprovedDate)}</Col>
        //                 <Col md={6} xl={2} data-itemid={this.props.ListItem.ID}>{this.props.ListItem.IVCardFieldATCCode}<br/>
        //                 <PrimaryButton className="button" onClick={() => {
        //                     let atcCode: string = this.props.AtcFilter=="" ? this.props.ListItem.IVCardFieldATCCode : "";
        //                      this.props.OnAtcFilter?.(atcCode); }}>{filterText}</PrimaryButton>
        //                 </Col>
        //             </Row>;
        // }

    }
    formatDate(IVCardFieldApprovedDate: Date): string {
        let returnValue: string;
        if (IVCardFieldApprovedDate===undefined) {
            returnValue = "";
        } else {
            let test = new Date(IVCardFieldApprovedDate);
            returnValue = test.toLocaleDateString();
        }
        return returnValue;
    }
    //#endregion

    //#region methods

    //#endregion
}

export class IVInfoBoxTop extends React.Component<IIVInfoBoxTopProps,{}> {
    //#region Life cycles
    public render(): React.ReactElement<IIVInfoBoxTopProps> {
        let topStyle: React.CSSProperties = {};
        if ((this.props.SetupInfo as IIVSetupInfo).Tooltip !== undefined) {
            topStyle = {textDecoration:"underline dotted red"};
        }
        const next: string = this.props.NotLast === true ? ", " : "";
        return <span style={topStyle}><Icon iconName={(this.props.SetupInfo as IIVSetupInfo).IconName}/><TooltipHost content={(this.props.SetupInfo as IIVSetupInfo).Tooltip} id={getId('tooltipHost')} calloutProps={{ gapSpace: 0 }}>
        {(this.props.SetupInfo as IIVSetupInfo).Title}
        </TooltipHost>{next}</span>
    }
    //#endregion
}

export class IVInfoBoxInfo extends React.Component<IIVInfoBoxTopProps,{}> {
    //#region Life cycles
    public render(): React.ReactElement<IIVInfoBoxTopProps> {
        let info: string = "";
        info = (this.props.ListItem as any)[(this.props.SetupInfo as IIVSetupInfo).Name];
        if ((this.props.SetupInfo as IIVSetupInfo).GeneralField) {
            info = (Data.Settings as any)[(this.props.SetupInfo as IIVSetupInfo).Name];
        }
        return <div className="ivInfo" style={(this.props.SetupInfo as IIVSetupInfo).Style} dangerouslySetInnerHTML={{ __html: info }} />
    }
    //#endregion
}

export class IVInfoBoxInfoMatrix extends React.Component<IIVInfoBoxTopProps,{}> {
    //#region Life cycles
    public render(): React.ReactElement<IIVInfoBoxTopProps> {
        let info: string = "";
        info = (this.props.ListItem as any)[(this.props.SetupInfo as IIVSetupInfo).Name];
        if ((this.props.SetupInfo as IIVSetupInfo).GeneralField) {
            info = (Data.Settings as any)[(this.props.SetupInfo as IIVSetupInfo).Name];
        }
        const setupInfo: IIVSetupInfo = (this.props.SetupInfo as IIVSetupInfo);
        return  <Container fluid>
                    { setupInfo.Rows?.map(row => {
                        return <Row>
                            { row.Cols.map(col => {
                                let content: string = col.Title === undefined ?"":col.Title;
                                if (col.TitleField !== undefined) {
                                    content = (this.props.ListItem as any)[col.TitleField];
                                }
                                return <Col>{content}</Col>
                            })}
                        </Row>
                    })}

                </Container>
        // return <div className="ivInfo" style={(this.props.SetupInfo as IIVSetupInfo).Style} dangerouslySetInnerHTML={{ __html: info }} />
    }
    //#endregion
}
